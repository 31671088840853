<template>
  <div class="container">
    <div class="spinner" id="spinner" v-if="logining"></div>
    <div class="errorIcon" id="errorIcon" v-else>!</div>
    <div class="message" id="message">{{ message}}</div>
  </div>
</template>

<script>

import store from '@/micro/globalStore'

export default {
  data() {
    return {
      message: "登录中，请稍后",
      logining: true,
    }
  },
  created() {
    var ticket = this.getParam('ticket')
    if (ticket) {
      this.doLoginByTicket(ticket)
    } else {
      this.goSsoAuthUrl()
    }
  },
  methods: {
    goSsoAuthUrl() {
      let clientLoginUrl = location.href
      if (!clientLoginUrl.includes('back')) {
        clientLoginUrl = clientLoginUrl + '?back=' + encodeURIComponent(location.origin + '/home')
      }
      this.$http('get_sso_getSsoAuthUrl', {clientLoginUrl: clientLoginUrl}, false).then((res) => {
        location.href = res.data
      })
    },
    doLoginByTicket(ticket) {
      this.$http('get_sso_doLoginByTicket', {ticket: ticket}, false).then((res) => {
        if (res.code === 200) {
          this.$store.commit('global/SET_TOKEN', res.data.token)
          this.$store.commit('global/SET_USERINFO', res.data.user)
          const global = this.$store.state.global
          this.$message.success('登录成功')
          store.setGlobalState({
            token: global.token,
            userInfo: global.userInfo
          })
          this.$store.dispatch('global/dyrouters').then((ress) => {
            this.$store.commit('global/SET_MENU', ress.data)
            var back = this.getParam('back', '/')
            setTimeout(() => {
              location.href = decodeURIComponent(back)
            }, 0)
          })
          this.$store.dispatch('global/getPersonInfo')
        } else {
          this.logining = false
          this.message = res.message
        }
      }).catch(err => {
        this.logining = false
        this.message = err.message
      })
    },
    getParam(name, defaultValue) {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === name) {
          return pair[1] + (pair[2] ? '=' + pair.slice(2).join('=') : '')
        }
      }
      return (defaultValue === undefined ? null : defaultValue)
    }
    // getLoginByNameAndToken() {
    //   if (this.$route.query.ssoToken && this.$route.query.ssoToken !== '') {
    //     const ssoToken = this.$route.query.ssoToken
    //     this.$http(
    //       'post_author_sso', { ssoToken: ssoToken }, false
    //     ).then((res) => {
    //       if (res.code === 200 && res.data) {
    //         // 保存用户信息
    //         this.loginWithSSO(res)
    //         // todo 直接跳转到执法平台首页
    //         this.$router.push({
    //           path: `/resource/homePage?token=${res.data}`,
    //           query: {
    //             isRelod: true
    //           }
    //         })
    //       } else {
    //         this.$router.push('/login')
    //       }
    //     })
    //   } else {
    //     this.$router.push('/login')
    //   }
    // },
    // /**
    //  * SSO登录操作
    //  * */
    // loginWithSSO(res) {
    //   // 保存token
    //   this.$store.commit('global/SET_TOKEN', res.data)
    //   const global = this.$store.state.global
    //   store.setGlobalState({
    //     token: global.token,
    //     userInfo: global.userInfo
    //   })
    //   this.$store.dispatch('global/dyrouters').then((res) => {
    //     this.$store.commit('global/SET_MENU', res.data)
    //     this.listConfig()
    //   })
    //   this.$store.dispatch('global/getPersonInfo')
    // },
    // // 跳转配置
    // listConfig() {
    //   this.$http(
    //     'post_author_listConfigUser',
    //     { configCode: 'defaultPage' },
    //     true
    //   ).then((res) => {
    //     if (res.code === 200 && res.data[0] && res.data[0].configValue) {
    //       const defaultIndex = res.data[0].configValue
    //       const { mainMenu } = this
    //       const ssMenuData = mainMenu.filter(
    //         (item) => item && item.name === '首页'
    //       )
    //       if (ssMenuData instanceof Array && ssMenuData.length > 0) {
    //         const ssMenuItem = ssMenuData[0].children
    //         history.pushState(
    //           null,
    //           '/home',
    //           ssMenuItem[defaultIndex] && ssMenuItem[defaultIndex].path
    //             ? ssMenuItem[defaultIndex].path
    //             : ssMenuItem[0].path
    //         )
    //       } else {
    //         history.pushState(
    //           null,
    //           '/home',
    //           '/home'
    //         )
    //       }
    //     } else {
    //       if (this.$route.query.token) {
    //         this.$router.replace(this.$route.path)
    //       } else {
    //         // history.pushState(null, '/home', '/home')
    //         // this.$router.push({
    //         //   path: '/home'
    //         // })
    //         this.$router.replace('/home')
    //       }
    //     }
    //   })
    // }
  }
}
</script>

<style scoped>
.container {
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 66px;
  height: 66px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.errorIcon {
  font-weight: bold;
  color: lightgrey;
  font-size: 46px;
  line-height: 50px;
  border: 8px solid lightgrey;
  border-top: 8px solid lightgray;
  border-radius: 50%;
  width: 66px;
  height: 66px;
  margin: 0 auto;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.message {
  margin-top: 20px;
  font-size: 16px;
  color: #555;
}
</style>
